<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">贷款申请</a>
      <label>></label>
      <a href="###">保证金贷款申请</a>
    </div>

    <div class="wrap-form-group">
      <el-form
        class="wrap-form"
        size="small"
        label-width="200px"
        label-position="left"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <template>
          <el-form-item label="招标机构">
            <span>{{ bidProject.bidOrg }}</span>
          </el-form-item>
          <el-form-item label="项目名称">
            <span>{{ bidProject.name }}</span>
          </el-form-item>
          <el-form-item label="招标金额">
            <span>{{ bidProject.bidMoney | currency }} 万元</span>
          </el-form-item>
          <el-form-item label="保证金金额">
            <span>{{ bidProject.marginMoney | currency }} 万元</span>
          </el-form-item>
          <el-form-item label="统一社会信用代码">
            <span>{{ bidProject.bidCode }}</span>
          </el-form-item>
        </template>

        <el-form-item label="贷款金额" prop="margin">
          <el-input v-model="form.margin" placeholder="请输入贷款金额">
            <div slot="suffix">万元</div>
          </el-input>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 69px">
        <el-button type="primary" @click="applyMarginLoan(1)">提交</el-button>
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var margin_rule = (rule, value, callback) => {
      if (Number(value) > Number(this.bidProject.marginMoney)) {
        callback(new Error("超过保证金金额，请重新输入"));
      } else if (Number(value) && Number(value) % 1 == 0) {
        callback();
      } else {
        callback(new Error("请输入贷款金额(整数型)"));
      }
    };
    return {
      bidProject: {},
      marginLoanStatus: "",
      marginLoanId: "",
      form: {
        bidProjectId: "", // 标的信息id
        margin: "", // 投标保证金

        status: "",
      },
      rules: {
        margin: [
          {
            required: true,
            validator: margin_rule,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get(`/bidProject/bidProjectDetails/${this.$route.params.id}`)
        .then((res) => {
          if (res.state == 200) {
            this.bidProject = res.data.bidProject;
            if (res.data.marginLoan) {
              this.marginLoanStatus = res.data.marginLoan.status;
              this.marginLoanId = res.data.marginLoan.id;
              this.form.margin = res.data.marginLoan.margin;
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 投标保证金贷款申请
    async applyMarginLoan(status) {
      let _this = this;
      _this.form.bidProjectId = _this.$route.params.id;

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let url = "";
          let data = {};
          if (this.marginLoanStatus == 3) {
            url = "/marginloan/updateMarginLoan";
            _this.form.bidProjectId = _this.marginLoanId;
            data = {
              id: _this.form.bidProjectId,
              margin: _this.form.margin * 1,
              status: status,
            };
          } else {
            url = "/marginloan/addMarginLoan";
            data = {
              bidProjectId: _this.form.bidProjectId,
              margin: _this.form.margin * 1,
              status: status,
            };
          }
          await _this.$axios
            .post(url, data, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              if (res.state == 200) {
                this.$message.success(res.message);
                _this.$router.back();
              } else if (res.state == 400) {
                this.$alert(res.message, "", {
                  confirmButtonText: "确定",
                });
              }
            });
        }
      });
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-form-group {
  width: 941px;
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .wrap-form {
    width: 400px;
    .input-row {
      display: flex;
    }
  }
}
</style>